@import './variables.scss';

.equip-prop-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #F5F5F5;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid black;
    cursor: pointer;
    letter-spacing: 1px;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    padding: 0px 0px 0px 60px;
    &:before {
      background-color: $secondary !important;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      margin: 8px 0 0 10px;
    }
  }

.equip-prop-status-container {
  height: 30px;
  width: 30px;
  position: relative;
}

.i-equip-prop-icon {
    padding: 16px !important;
    background-size: 26px !important;
    content: '' !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
}

.i-thermometer:before {
    @extend .i-equip-prop-icon;
    background: transparent url('/assets/images/thermometer_wh.svg') no-repeat center center;
}

.i-radiator-bathroom:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/radiator_bathroom_wh.svg') no-repeat center center;
}

.i-radiator-bathroom-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/radiator_bathroom_bl.svg') no-repeat center center;
}

.i-battery-off:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/battery_off.svg') no-repeat center center;
}

.i-battery-low:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/battery_low.svg') no-repeat center center;
}

.i-battery-auto:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/battery_auto.svg') no-repeat center center;
}

.i-blinds-up:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/blind-arrow-up.svg') no-repeat center center;
}

.i-blinds-down:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/blind-arrow-down.svg') no-repeat center center;
}


.i-floor-heating:before {
  @extend .i-equip-prop-icon;
  background-size: 22px !important;
  background: transparent url('/assets/images/floor_heating_wh.svg') no-repeat center center;
}

.i-floor-heating-bl:before {
  @extend .i-equip-prop-icon;
  background-size: 22px !important;
  background: transparent url('/assets/images/floor_heating_bl.svg') no-repeat center center;
}

.i-convection-heater-wh:before {
  @extend .i-equip-prop-icon;
  background-size: 22px !important;
  background: transparent url('/assets/images/convection_heater_wh.svg') no-repeat center center;
}
.i-convection-heater-bl:before {
  @extend .i-equip-prop-icon;
  background-size: 22px !important;
  background: transparent url('/assets/images/convection_heater_bl.svg') no-repeat center center;
}

.i-heating-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/heating_bl.svg') no-repeat center center;
}

.i-cooling-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/cooling_bl.svg') no-repeat center center;
}

.i-active-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/active_bl.svg') no-repeat center center;
}

.i-room-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/room_bl.svg') no-repeat center center;
}

.i-bathroom-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/bathroom_bl.svg') no-repeat center center;
}

.i-fan-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/fan_bl.svg') no-repeat center center;
}

.i-burglary-window-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/burglary_window_bl.svg') no-repeat center center;
}

.i-burglary-window-wh:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/burglary_window_wh.svg') no-repeat center center;
}

.i-infrared-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/infrared_bl.svg') no-repeat center center;
}

.i-infrared-wh:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/infrared_wh.svg') no-repeat center center;
}

.i-burglary-boor_bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/burglary_boor_bl.svg') no-repeat center center;
}

.i-safe-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/safe_bl.svg') no-repeat center center;
}

.i-burglary-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/burglary_bl.svg') no-repeat center center;
}

.i-safe-error-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/safe_error_bl.svg') no-repeat center center;
}

.i-room-armed-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/room_armed_bl.svg') no-repeat center center;
}

.i-communication-error-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/communication_error_bl.svg') no-repeat center center;
}

.i-contactor-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/contactor_bl.svg') no-repeat center center;
}

.i-door-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/door_black.svg') no-repeat center center;
}

.i-safe-door-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/safe_door_bl.svg') no-repeat center center;
}

.i-window-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/window_bl.svg') no-repeat center center;
}

.i-window-wh:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/window_wh.svg') no-repeat center center;
}

.i-door-lock-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/door_lock_bl.svg') no-repeat center center;
}

.i-light-on-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/light-bulb-on.svg') no-repeat center center;
}

.i-card-tray-wh:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/card_tray_wh.svg') no-repeat center center;
}

.i-panel-wh:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/panel_wh.svg') no-repeat center center;
}

.i-dimmable-light-wh:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/dimmable_light_wh.svg') no-repeat center center;
}

.i-room-radiator-wh:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/room_radiator_wh.svg') no-repeat center center;
}

.i-room-radiator-bl:before {
  @extend .i-equip-prop-icon;
  background: transparent url('/assets/images/room_radiator_bl.svg') no-repeat center center;
}


